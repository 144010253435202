import type { ReactElement } from 'react';

import type { SelectProps } from './selects/Select';
import Select from './selects/Select';

type OmittedProps = 'isSearchable' | 'preventFilterSearch';

export type SingleTypeaheadProps<Value> = Omit<
  SelectProps<Value>,
  OmittedProps
>;

export const SingleTypeahead = <Value,>(
  props: SingleTypeaheadProps<Value>,
): ReactElement => <Select isSearchable preventFilterSearch {...props} />;
