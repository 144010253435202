// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
export type CoachCardCoachFragment = {
  __typename?: 'Coach';
  id: string;
  slug: string;
  user: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
  };
  categoryInformationList?: Array<{
    __typename?: 'CategoryInformation';
    category: { __typename?: 'Category'; id: string; name: string };
  }> | null;
};

export const CoachCardCoachFragmentDoc = gql`
  fragment CoachCard_Coach on Coach {
    id
    slug
    user {
      id
      firstName
      lastName
      pictureLink
    }
    categoryInformationList {
      category {
        id
        name
      }
    }
  }
`;
