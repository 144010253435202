// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CategoryTypeaheadCategoryFragment = {
  __typename?: 'Category';
  id: string;
  goal: Types.GoalName;
  name: string;
  slug: string;
};

export type CategoryTypeaheadQueryVariables = Types.Exact<{
  goal?: Types.InputMaybe<Types.GoalName>;
  query: Types.Scalars['String']['input'];
  featuredOnly?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  hasActiveCoachOnly?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  count?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type CategoryTypeaheadQuery = {
  __typename?: 'Query';
  categoryTypeahead: Array<{
    __typename?: 'Category';
    id: string;
    goal: Types.GoalName;
    name: string;
    slug: string;
  }>;
};

export const CategoryTypeaheadCategoryFragmentDoc = gql`
  fragment CategoryTypeahead_Category on Category {
    id
    goal
    name
    slug
  }
`;
export const CategoryTypeaheadDocument = gql`
  query CategoryTypeahead(
    $goal: GoalName
    $query: String!
    $featuredOnly: Boolean = false
    $hasActiveCoachOnly: Boolean = true
    $count: Int = 20
  ) {
    categoryTypeahead(
      goal: $goal
      query: $query
      featuredOnly: $featuredOnly
      hasActiveCoachOnly: $hasActiveCoachOnly
      count: $count
    ) {
      ...CategoryTypeahead_Category
    }
  }
  ${CategoryTypeaheadCategoryFragmentDoc}
`;

/**
 * __useCategoryTypeaheadQuery__
 *
 * To run a query within a React component, call `useCategoryTypeaheadQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryTypeaheadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryTypeaheadQuery({
 *   variables: {
 *      goal: // value for 'goal'
 *      query: // value for 'query'
 *      featuredOnly: // value for 'featuredOnly'
 *      hasActiveCoachOnly: // value for 'hasActiveCoachOnly'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useCategoryTypeaheadQuery(
  baseOptions: Apollo.QueryHookOptions<
    CategoryTypeaheadQuery,
    CategoryTypeaheadQueryVariables
  > &
    (
      | { variables: CategoryTypeaheadQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CategoryTypeaheadQuery,
    CategoryTypeaheadQueryVariables
  >(CategoryTypeaheadDocument, options);
}
export function useCategoryTypeaheadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoryTypeaheadQuery,
    CategoryTypeaheadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CategoryTypeaheadQuery,
    CategoryTypeaheadQueryVariables
  >(CategoryTypeaheadDocument, options);
}
export function useCategoryTypeaheadSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CategoryTypeaheadQuery,
    CategoryTypeaheadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CategoryTypeaheadQuery,
    CategoryTypeaheadQueryVariables
  >(CategoryTypeaheadDocument, options);
}
export type CategoryTypeaheadQueryHookResult = ReturnType<
  typeof useCategoryTypeaheadQuery
>;
export type CategoryTypeaheadLazyQueryHookResult = ReturnType<
  typeof useCategoryTypeaheadLazyQuery
>;
export type CategoryTypeaheadSuspenseQueryHookResult = ReturnType<
  typeof useCategoryTypeaheadSuspenseQuery
>;
export type CategoryTypeaheadQueryResult = Apollo.QueryResult<
  CategoryTypeaheadQuery,
  CategoryTypeaheadQueryVariables
>;
