// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LelandPlusCoachFragment = {
  __typename?: 'Coach';
  id: string;
  slug: string;
  user: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
  };
};

export type LelandPlusCoachBatchResponseFragment = {
  __typename?: 'CoachBatchResponse';
  total: number;
  results: Array<{
    __typename?: 'Coach';
    id: string;
    slug: string;
    user: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      pictureLink?: string | null;
    };
  }>;
};

export type LelandPlusLelandPlusContributorsQueryVariables = Types.Exact<{
  categoryUrn: Types.Scalars['CategoryUrn']['input'];
}>;

export type LelandPlusLelandPlusContributorsQuery = {
  __typename?: 'Query';
  lelandPlusContributors: {
    __typename?: 'CoachBatchResponse';
    total: number;
    results: Array<{
      __typename?: 'Coach';
      id: string;
      slug: string;
      user: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        pictureLink?: string | null;
      };
    }>;
  };
};

export type LelandPlusUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type LelandPlusUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    applicant?: {
      __typename?: 'Applicant';
      id: string;
      lelandPlus?: { __typename?: 'LelandPlus'; active: boolean } | null;
    } | null;
  } | null;
};

export const LelandPlusCoachFragmentDoc = gql`
  fragment LelandPlus_Coach on Coach {
    id
    user {
      id
      firstName
      lastName
      pictureLink
    }
    slug
  }
`;
export const LelandPlusCoachBatchResponseFragmentDoc = gql`
  fragment LelandPlus_CoachBatchResponse on CoachBatchResponse {
    results {
      ...LelandPlus_Coach
    }
    total
  }
  ${LelandPlusCoachFragmentDoc}
`;
export const LelandPlusLelandPlusContributorsDocument = gql`
  query LelandPlus_lelandPlusContributors($categoryUrn: CategoryUrn!) {
    lelandPlusContributors(category: $categoryUrn) {
      ...LelandPlus_CoachBatchResponse
    }
  }
  ${LelandPlusCoachBatchResponseFragmentDoc}
`;

/**
 * __useLelandPlusLelandPlusContributorsQuery__
 *
 * To run a query within a React component, call `useLelandPlusLelandPlusContributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLelandPlusLelandPlusContributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLelandPlusLelandPlusContributorsQuery({
 *   variables: {
 *      categoryUrn: // value for 'categoryUrn'
 *   },
 * });
 */
export function useLelandPlusLelandPlusContributorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LelandPlusLelandPlusContributorsQuery,
    LelandPlusLelandPlusContributorsQueryVariables
  > &
    (
      | {
          variables: LelandPlusLelandPlusContributorsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LelandPlusLelandPlusContributorsQuery,
    LelandPlusLelandPlusContributorsQueryVariables
  >(LelandPlusLelandPlusContributorsDocument, options);
}
export function useLelandPlusLelandPlusContributorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LelandPlusLelandPlusContributorsQuery,
    LelandPlusLelandPlusContributorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LelandPlusLelandPlusContributorsQuery,
    LelandPlusLelandPlusContributorsQueryVariables
  >(LelandPlusLelandPlusContributorsDocument, options);
}
export function useLelandPlusLelandPlusContributorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LelandPlusLelandPlusContributorsQuery,
    LelandPlusLelandPlusContributorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LelandPlusLelandPlusContributorsQuery,
    LelandPlusLelandPlusContributorsQueryVariables
  >(LelandPlusLelandPlusContributorsDocument, options);
}
export type LelandPlusLelandPlusContributorsQueryHookResult = ReturnType<
  typeof useLelandPlusLelandPlusContributorsQuery
>;
export type LelandPlusLelandPlusContributorsLazyQueryHookResult = ReturnType<
  typeof useLelandPlusLelandPlusContributorsLazyQuery
>;
export type LelandPlusLelandPlusContributorsSuspenseQueryHookResult =
  ReturnType<typeof useLelandPlusLelandPlusContributorsSuspenseQuery>;
export type LelandPlusLelandPlusContributorsQueryResult = Apollo.QueryResult<
  LelandPlusLelandPlusContributorsQuery,
  LelandPlusLelandPlusContributorsQueryVariables
>;
export const LelandPlusUserDocument = gql`
  query LelandPlus_User {
    user {
      id
      applicant {
        id
        lelandPlus {
          active
        }
      }
    }
  }
`;

/**
 * __useLelandPlusUserQuery__
 *
 * To run a query within a React component, call `useLelandPlusUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLelandPlusUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLelandPlusUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useLelandPlusUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LelandPlusUserQuery,
    LelandPlusUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LelandPlusUserQuery, LelandPlusUserQueryVariables>(
    LelandPlusUserDocument,
    options,
  );
}
export function useLelandPlusUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LelandPlusUserQuery,
    LelandPlusUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LelandPlusUserQuery, LelandPlusUserQueryVariables>(
    LelandPlusUserDocument,
    options,
  );
}
export function useLelandPlusUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LelandPlusUserQuery,
    LelandPlusUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LelandPlusUserQuery,
    LelandPlusUserQueryVariables
  >(LelandPlusUserDocument, options);
}
export type LelandPlusUserQueryHookResult = ReturnType<
  typeof useLelandPlusUserQuery
>;
export type LelandPlusUserLazyQueryHookResult = ReturnType<
  typeof useLelandPlusUserLazyQuery
>;
export type LelandPlusUserSuspenseQueryHookResult = ReturnType<
  typeof useLelandPlusUserSuspenseQuery
>;
export type LelandPlusUserQueryResult = Apollo.QueryResult<
  LelandPlusUserQuery,
  LelandPlusUserQueryVariables
>;
