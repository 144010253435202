import { compact } from '@leland-dev/leland-ui-library';

type KeysWithValsOfType<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

const escapeRegExp = (s: string) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

/**
 * modified from https://www.peterbe.com/plog/a-darn-good-search-filter-function-in-javascript
 */
export const filterSearch = <T>(
  query: string,
  list: T[],
  keys: Array<KeysWithValsOfType<T, string>>,
): T[] => {
  const qParts = query
    .split(/\s+/g)
    .map((s) => s.trim() || null)
    .filter(compact);

  const hasTrailingSpace = query.endsWith(' ');
  const searchRegExp = new RegExp(
    qParts
      .map((q, i) => {
        if (i === qParts.length - 1 && !hasTrailingSpace) {
          return `.*${escapeRegExp(q)}`;
        }
        return `.*${escapeRegExp(q)}`;
      })
      .join(''),
    'i',
  );

  return list.filter((item) => {
    if (keys) {
      return keys.some((k) => searchRegExp.test(item[k] as unknown as string));
    }
  });
};
