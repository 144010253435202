import { IconCheckmarkBadge } from '@leland-dev/leland-ui-library';
import Image from 'next/image';
import { type FC, type PropsWithChildren, type ReactNode } from 'react';

import ExpertHeroImage from '../../assets/images/checklist-hero.png';

export interface ChecklistItemPointProps {
  title: string;
  description: ReactNode;
}

const ChecklistItemPoint: FC<ChecklistItemPointProps> = ({
  title,
  description,
}) => {
  return (
    <div className="mt-4">
      <h3 className="flex items-center gap-2 text-xl font-medium">
        <IconCheckmarkBadge className="size-4 shrink-0" />
        <span>{title}</span>
      </h3>
      <p className="mt-2 text-leland-gray-light">{description}</p>
    </div>
  );
};

interface ChecklistHeroProps {
  title: string;
  itemPoints: ChecklistItemPointProps[];
  underListContent?: ReactNode;
  className?: string;
}

export const ChecklistHero: FC<PropsWithChildren<ChecklistHeroProps>> = ({
  children,
  className,
  title,
  itemPoints,
  underListContent,
}) => {
  return (
    <section
      className={`mt-16 w-full bg-leland-beige py-16 sm:mt-20 sm:py-32 ${className}`}
    >
      <div className="relative mb-20 flex w-full flex-col">
        <div className="mx-auto max-w-7xl space-y-10 px-6 sm:px-20">
          <div className="flex w-full flex-col-reverse items-start justify-between gap-10 sm:flex-row sm:gap-20">
            <div className="flex max-w-sm grow flex-col">
              <h2 className="font-condensed text-4xl font-medium">{title}</h2>
              {itemPoints.map((item, index) => (
                <ChecklistItemPoint key={index} {...item} />
              ))}
            </div>
            <Image
              src={ExpertHeroImage}
              alt="Expert Hero Image"
              width={568}
              height={363}
            />
          </div>
          {underListContent}
        </div>
      </div>
      {children}
    </section>
  );
};
