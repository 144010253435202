import dynamic from 'next/dynamic';
import { type ComponentType, type HTMLProps, type ReactElement } from 'react';

const Certificate = dynamic(() => import('../assets/svgs/certificate.svg'));
const Chevron = dynamic(() => import('../assets/svgs/chevron.svg'));
const GroupClasses = dynamic(() => import('../assets/svgs/group-classes.svg'));
const Help = dynamic(() => import('../assets/svgs/help.svg'));
const Home = dynamic(() => import('../assets/svgs/home.svg'));
const Instagram = dynamic(() => import('../assets/svgs/instagram.svg'));
const LelandCompass = dynamic(
  () => import('../assets/svgs/leland-compass.svg'),
);
const LinkedInSquare = dynamic(
  () => import('../assets/svgs/linkedin-square.svg'),
);
const Loader = dynamic(() => import('../assets/svgs/loader.svg'));
const Profile = dynamic(() => import('../assets/svgs/profile.svg'));
const Search = dynamic(() => import('../assets/svgs/search.svg'));
const Signout = dynamic(() => import('../assets/svgs/sign-out.svg'));
const Slack = dynamic(() => import('../assets/svgs/slack.svg'));
const Star = dynamic(() => import('../assets/svgs/star.svg'));
const Switch = dynamic(() => import('../assets/svgs/switch.svg'));
const User = dynamic(() => import('../assets/svgs/user.svg'));
const Tiktok = dynamic(() => import('../assets/svgs/tiktok.svg'));

export enum Svg {
  CERTIFICATE = 'CERTIFICATE',
  CHEVRON = 'CHEVRON',
  GROUP_CLASSES = 'GROUP_CLASSES',
  HELP = 'HELP',
  HOME = 'HOME',
  INSTAGRAM = 'INSTAGRAM',
  LELAND_COMPASS = 'LELAND_COMPASS',
  LINKEDIN_SQUARE = 'LINKEDIN_SQUARE',
  LOADER = 'LOADER',
  PROFILE = 'PROFILE',
  SEARCH = 'SEARCH',
  SIGN_OUT = 'SIGN_OUT',
  SLACK = 'SLACK',
  STAR = 'STAR',
  SWITCH = 'SWITCH',
  USER = 'USER',
  TIKTOK = 'TIKTOK',
}

const SVG_STRING: Record<Svg, ComponentType> = Object.freeze({
  [Svg.CERTIFICATE]: Certificate,
  [Svg.CHEVRON]: Chevron,
  [Svg.GROUP_CLASSES]: GroupClasses,
  [Svg.HELP]: Help,
  [Svg.HOME]: Home,
  [Svg.INSTAGRAM]: Instagram,
  [Svg.LELAND_COMPASS]: LelandCompass,
  [Svg.LINKEDIN_SQUARE]: LinkedInSquare,
  [Svg.LOADER]: Loader,
  [Svg.PROFILE]: Profile,
  [Svg.SEARCH]: Search,
  [Svg.SIGN_OUT]: Signout,
  [Svg.SLACK]: Slack,
  [Svg.STAR]: Star,
  [Svg.SWITCH]: Switch,
  [Svg.TIKTOK]: Tiktok,
  [Svg.USER]: User,
});

interface Props extends HTMLProps<HTMLElement> {
  svgType: Svg;
}

const SvgIcon = ({ svgType, ...props }: Props): ReactElement => {
  const SVGComponent = SVG_STRING[svgType];

  if (!SVGComponent) {
    throw new Error('Svg type is not supported');
  }

  return <SVGComponent {...props} />;
};

export default SvgIcon;
