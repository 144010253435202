import type React from 'react';

import SvgIcon, { Svg } from '../SvgIcon';

interface Props {
  className?: string;
  iconClassName?: string;
  text?: string;
}

const LoadingIndicator: React.FC<Props> = ({
  className,
  iconClassName,
  text,
}) => (
  <div
    className={`flex size-full flex-col items-center justify-center p-2 ${className}`}
  >
    <SvgIcon svgType={Svg.LOADER} className={`animate-spin ${iconClassName}`} />
    {text ? <h2 className="mt-8 text-2xl">{text}</h2> : null}
  </div>
);

export default LoadingIndicator;
