import unionBy from 'lodash/unionBy';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { type GoalName } from '../../__generated-gql-types__/globalTypes';
import { useDebounce } from '../../utils/hooks/useDebounce';
import {
  SingleTypeahead,
  type SingleTypeaheadProps,
} from '../inputs/Typeahead';
import { type SelectOptionType } from '../inputs/selects';

import {
  type CategoryTypeaheadCategoryFragment,
  useCategoryTypeaheadQuery,
} from './__generated-gql-types__/CategoryTypeahead.generated';

export type CategoryTypeaheadCategory = CategoryTypeaheadCategoryFragment;

type SingleCategoryTypeaheadProps = Omit<
  SingleTypeaheadProps<CategoryTypeaheadCategory>,
  'options' | 'onInputChange'
> & {
  goal?: Nullable<GoalName>;
};

export const SingleCategoryTypeahead: React.FC<
  SingleCategoryTypeaheadProps
> = ({ goal, value, placeholder = 'Search categories...', ...props }) => {
  const queryProps = useCategoryTypeaheadQueryData({ goal, value });

  return (
    <SingleTypeahead
      {...props}
      value={value}
      placeholder={placeholder}
      {...queryProps}
    />
  );
};

const categoryToSelectOptionType = (
  category: CategoryTypeaheadCategory,
): SelectOptionType<CategoryTypeaheadCategory> => ({
  value: category,
  label: category.name,
});

const useCategoryTypeaheadQueryData = ({
  goal,
  value,
  values,
}: {
  goal?: Nullable<GoalName>;
  value?: Nullable<CategoryTypeaheadCategory>;
  values?: CategoryTypeaheadCategory[];
}) => {
  const [rawQuery, setRawQuery] = useState<string>('');
  const query = useDebounce(rawQuery, 200);
  const onInputChange = useCallback((input: string) => setRawQuery(input), []);

  const { data, loading } = useCategoryTypeaheadQuery({
    variables: { goal, query },
  });

  const options: Array<SelectOptionType<CategoryTypeaheadCategory>> =
    useMemo(() => {
      const categories = unionBy(
        data?.categoryTypeahead ?? [],
        value ? [value] : values,
        'id',
      );
      return categories.map(categoryToSelectOptionType);
    }, [data?.categoryTypeahead, value, values]);

  return { onInputChange, options, loading };
};
