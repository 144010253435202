import { type FC } from 'react';

import BusinessInsiderLogo from '../../assets/images/homepage/press-logos/business-insider-logo.svg';
import ForbesLogo from '../../assets/images/homepage/press-logos/forbes-logo.svg';
import TechCrunchLogo from '../../assets/images/homepage/press-logos/tech-crunch-logo.svg';

const PressLogos = [
  {
    url: 'https://www.forbes.com/sites/rodberger/2022/05/23/democratizing-the-college-admissions-process-is-big-business/?sh=26db187369c9',
    image: <ForbesLogo />,
  },
  {
    url: 'https://www.businessinsider.com/most-promising-edtech-startups-2023-vc-2023-9',
    image: <BusinessInsiderLogo />,
  },
  {
    url: 'https://techcrunch.com/2022/05/11/leland-coaching-seed-round/#!',
    image: <TechCrunchLogo />,
  },
];

export const Press: FC = () => {
  return (
    <section className="mt-4 flex flex-wrap items-center justify-center gap-6 bg-leland-black px-6 py-8 text-leland-white sm:gap-14">
      <span className="text-lg font-medium">Featured in</span>
      {PressLogos.map((logo, index) => {
        return (
          <a
            className="transition-opacity duration-200 ease-in-out hover:opacity-70"
            key={index}
            href={logo.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {logo.image}
          </a>
        );
      })}
    </section>
  );
};
