// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { CoachCardCoachFragmentDoc } from '../../common/__generated-gql-types__/CoachCard.generated';
export type ExpertHelpCtaCoachFragment = {
  __typename?: 'Coach';
  id: string;
  slug: string;
  user: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
  };
  categoryInformationList?: Array<{
    __typename?: 'CategoryInformation';
    category: { __typename?: 'Category'; id: string; name: string };
  }> | null;
};

export const ExpertHelpCtaCoachFragmentDoc = gql`
  fragment ExpertHelpCta_Coach on Coach {
    ...CoachCard_Coach
  }
  ${CoachCardCoachFragmentDoc}
`;
