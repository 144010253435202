// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { EventCardEventFragmentDoc } from '../../common/__generated-gql-types__/EventCard.generated';
import {
  ClassCardClassFragmentDoc,
  ClassCardClassSessionFragmentDoc,
} from '../../common/__generated-gql-types__/ClassCard.generated';
export type UpcomingEventsEventFragment = {
  __typename?: 'Shindig';
  imageLink?: string | null;
  date: number;
  link: string;
  numGuests?: number | null;
  shindigId: string;
  shindigName: string;
  coaches?: Array<{
    __typename?: 'Coach';
    id: string;
    slug: string;
    reviewTotal: number;
    averageReviewRate: number;
    categoryInformationList?: Array<{
      __typename?: 'CategoryInformation';
      category: { __typename?: 'Category'; id: string; slug: string };
    }> | null;
    user: {
      __typename?: 'User';
      pictureLink?: string | null;
      firstName: string;
      lastName: string;
    };
  }> | null;
  categories?: Array<{
    __typename?: 'CategorySelection';
    category: { __typename?: 'Category'; id: string; slug: string };
  }> | null;
  guests?: Array<{ __typename?: 'Guest'; name?: string | null }> | null;
};

export type UpcomingEventsClassFragment = {
  __typename?: 'Class';
  imageLink?: string | null;
  slug?: string | null;
  price?: number | null;
  durationInMinute?: number | null;
  maximumAttendees?: number | null;
  isUserOnDiscoveryList?: boolean | null;
  isFreeEvent?: boolean | null;
  classId: string;
  className?: string | null;
  upcomingSessionsV2?: Array<{
    __typename?: 'CoachingSession';
    id: string;
    startAt: number;
    totalAttendees: number;
    userIsEnrolled?: boolean | null;
    topFacePiles: Array<string>;
  }> | null;
  category?: { __typename?: 'Category'; id: string; slug: string } | null;
  coach?: {
    __typename?: 'Coach';
    id: string;
    slug: string;
    averageReviewRate: number;
    reviewTotal: number;
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      pictureLink?: string | null;
    };
    coachingRelationship?: {
      __typename?: 'CoachingRelationship';
      id: string;
      blockedByCoach: boolean;
    } | null;
    categoryInformationList?: Array<{
      __typename?: 'CategoryInformation';
      category: { __typename?: 'Category'; id: string; slug: string };
    }> | null;
  } | null;
};

export const UpcomingEventsEventFragmentDoc = gql`
  fragment UpcomingEvents_Event on Shindig {
    ...EventCard_Event
  }
  ${EventCardEventFragmentDoc}
`;
export const UpcomingEventsClassFragmentDoc = gql`
  fragment UpcomingEvents_Class on Class {
    ...ClassCard_Class
    upcomingSessionsV2(ignoreFullSessions: true) {
      id
      ...ClassCard_ClassSession
    }
  }
  ${ClassCardClassFragmentDoc}
  ${ClassCardClassSessionFragmentDoc}
`;
