import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client';
import { LelandPlusBanner } from '@leland-dev/leland-ui-library';
import { type FC } from 'react';

import LelandPlusLogo from '../../assets/svgs/leland-plus-logo.svg';
import { categorySlugToUrn } from '../../utils/category';
import { MBA_CATEGORY_SLUG } from '../../utils/constants';
import { getLelandPlusLandingPageUrl } from '../../utils/leland-plus';
import { getCoachProfileUrl } from '../../utils/routing';
import { LelandPlusSchoolImages } from '../common/LelandPlusImages';

import {
  type LelandPlusCoachBatchResponseFragment,
  LelandPlusLelandPlusContributorsDocument,
  type LelandPlusLelandPlusContributorsQuery,
  type LelandPlusLelandPlusContributorsQueryVariables,
  useLelandPlusUserQuery,
} from './__generated-gql-types__/LelandPlus.generated';

export const getLelandPlusContributorsStaticProps = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
) => {
  const { data } = await apolloClient.query<
    LelandPlusLelandPlusContributorsQuery,
    LelandPlusLelandPlusContributorsQueryVariables
  >({
    query: LelandPlusLelandPlusContributorsDocument,
    variables: {
      categoryUrn: categorySlugToUrn(MBA_CATEGORY_SLUG),
    },
  });

  return data.lelandPlusContributors;
};

interface LelandPlusProps {
  contributors: LelandPlusCoachBatchResponseFragment;
}

export const LelandPlus: FC<LelandPlusProps> = ({ contributors }) => {
  const { data } = useLelandPlusUserQuery();

  const isLelandPlusUser = !!data?.user?.applicant?.lelandPlus?.active;

  return (
    <section className="relative top-64 mx-auto -mt-44 w-full max-w-7xl px-4 sm:top-36 sm:mt-0 sm:px-0">
      <LelandPlusBanner
        hideFreeTrial={isLelandPlusUser}
        numCoaches={contributors.total}
        featuredCoaches={contributors?.results?.map((coach) => {
          return {
            id: coach.id,
            firstName: coach.user.firstName,
            lastName: coach.user.lastName,
            slug: coach.slug,
            pictureLink: coach.user.pictureLink ?? undefined,
            url: getCoachProfileUrl(coach.slug),
          };
        })}
        ctaLabel={isLelandPlusUser ? 'Go to Leland+' : 'Get started for free'}
        landingPageUrl={getLelandPlusLandingPageUrl({
          isLelandPlusUser,
          categorySlug: null,
          utmMedium: 'homepage',
        })}
        lelandPlusIcon={<LelandPlusLogo />}
        imageCtas={LelandPlusSchoolImages}
      />
    </section>
  );
};
